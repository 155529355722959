.landing-home {
    grid-column: 2 / 12;
    margin: auto;
    text-align: center !important;
}
.home {
    grid-column: col-start 1 / span 13;
    margin: auto;
    text-align: center !important;
}

.text-teal {
    color: teal;
}
.text-green {
    color: #32a852;
}

.name-animation {
    color: #32a852;
    transition: 0.5s;
}
.name-animation:hover {
    color: #308bcf;
}

.mb-0 {
    margin: 2px;
}

h1 {
    font-size: 48px;
    font-weight: 400;
    transition: 0.5s;
    pointer-events: none;
}
h1 span {
    pointer-events: auto;
    cursor: pointer;
}
@media only screen and (max-width: 500px) {
    h1 {
        font-size: 28px;
    }
}

.btn {
    margin-top: 30px;
    background-color: transparent;
    border: #32a852 3px solid;
    padding: 10px 40px 10px 40px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
    color: white;
    transition: 0.5s ease-in-out;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
.btn:hover {
    border: #308bcf solid 3px;
    color: #fff;
}
.btn:hover .rotate-right {
    transform: rotate(90deg);
    transition: 0.5s;
}

.font-24 {
    font-size: 24px;
}

a {
    text-decoration: none;
    font-weight: 400;
}
a:hover {
    text-decoration: none;
}

footer {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-auto-flow: column;
    min-height: 150px;
    justify-content: center !important;
    align-content: center;
    text-align: center;
    color: white;
}

.margin-x-3 {
    margin: 0 30px 0 30px;
}

.font-icon {
    color: white;
    cursor: pointer;
    transition: 0.5s;
}
.font-icon:hover {
    color: #32a852;
    transform: scale(1.1);
}