body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.vh-100 {
  height: 100vh;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-auto-flow: column;
  height: 100vh;
  justify-content: center !important;
  align-content: center;
  color: white;
}

.bg-white {
  background-color: #fff;
}
.text-black {
  color: #000;
}

.caps {
  text-transform: uppercase;
}

.font-32 {
  font-size: 32px;
}

.title-underline {
  text-decoration: underline #32a852 3px solid;
}

.text-orange {
  color: #CF7430;
}