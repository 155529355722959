.project-grid {
    display: grid;
    grid-template-columns: repeat(9, minmax(0, 1fr));
    grid-template-rows: 100px repeat(3, 1fr);
    min-height: 100vh;
    grid-gap: 10px;
    justify-content: center !important;
    padding: 0 50px 0 50px;
}

.project-grid-header {
    grid-column-start: 1;
    grid-column-end: 10;
    margin: auto;
    text-align: center !important;
}

.project-grid-1 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center !important;
    background: url("./ATN3WZSTATS.png") no-repeat center;
    cursor: pointer;
}
.project-grid-1 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-1:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-2 {
    grid-row-start: 2;
    grid-column-start: 4;
    grid-column-end: 7;
    text-align: center !important;
    background: url("./SLW.png") no-repeat center;
    cursor: pointer;
}
.project-grid-2 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-2:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-3 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: 10;
    text-align: center !important;
    background: url("./AFTTS.png") no-repeat center;
    cursor: pointer;
}
.project-grid-3 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-3:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-4 {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center !important;
    background: url("./AFXNH.png") no-repeat center;
    cursor: pointer;
}
.project-grid-4 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-4:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-5 {
    grid-row-start: 3;
    grid-column-start: 4;
    grid-column-end: 7;
    text-align: center !important;
    background: url("./GR.png") no-repeat center;
    cursor: pointer;
}
.project-grid-5 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-5:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-6 {
    grid-row-start: 3;
    grid-column-start: 7;
    grid-column-end: 10;
    text-align: center !important;
    background: url("./HEROES.png") no-repeat center;
    cursor: pointer;
}
.project-grid-6 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-6:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-7 {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center !important;
    background: url("./CM.png") no-repeat center;
    cursor: pointer;
}
.project-grid-7 h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #32a852;
    font-weight: 700;
    font-size: 3vw;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
}
.project-grid-7:hover h2 {
    opacity: 1;
    background-color: #131413;
}

.project-grid-8 {
    grid-row-start: 4;
    grid-column-start: 4;
    grid-column-end: 7;
    text-align: center !important;
    background: #32a852;
    cursor: pointer;
}
@media only screen and (max-width: 850px) {
    .project-grid {
        grid-template-rows: 100px repeat(4, 1fr);
    }
    .project-grid-1 {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    .project-grid-2 {
        grid-row-start: 2;
        grid-column-start: 6;
        grid-column-end: 10;
    }
    .project-grid-3 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    .project-grid-4 {
        grid-row-start: 3;
        grid-column-start: 6;
        grid-column-end: 10;
    }
    .project-grid-5 {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    .project-grid-6 {
        grid-row-start: 4;
        grid-column-start: 6;
        grid-column-end: 10;
    }
    .project-grid-7 {
        grid-row-start: 5;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    .project-grid-8 {
        grid-row-start: 5;
        grid-column-start: 6;
        grid-column-end: 10;
    }
}
@media only screen and (max-width: 650px) {
    .project-grid {
        grid-template-rows: 100px repeat(7, auto);
    }
    .project-grid-1 {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-2 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-3 {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-4 {
        grid-row-start: 5;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-5 {
        grid-row-start: 6;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-6 {
        grid-row-start: 7;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-7 {
        grid-row-start: 8;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
    .project-grid-8 {
        grid-row-start: 9;
        grid-column-start: 1;
        grid-column-end: 10;
        border: #32a852 1px solid;
    }
}
a {
    cursor: pointer;
}

/* Modal CSS */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 85%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}
@-webkit-keyframes animatetop {
    from {opacity:0}
    to {opacity:1}
}
@keyframes animatetop {
    from {opacity:0}
    to {opacity:1}
}
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-header {
    padding: 2px 16px;
    background-color: #32a852;
    color: white;
    text-align: center;
}
.modal-body {
    padding: 2px 16px;
}
.modal-footer {
    padding: 2px 16px;
    background-color: #32a852;
    color: white;
    text-align: center;
}
.modal-footer a {
    color: white;
}
.modal-footer a:hover {
    color: #308bcf;
}

.block {
    display: block;
}