a {
    cursor: pointer;
}
.form-style {
    margin-bottom: 10px;
}
.form-style input {
    border: #32a852 2px solid;
    border-radius: 12px;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    box-sizing: border-box;
}
.form-style textarea {
    border: #32a852 2px solid;
    border-radius: 12px;
    width: 100%;
    height: 120px;
    padding: 10px;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    box-sizing: border-box;
}

.button-style {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    background: #32a852;
    border-radius: 12px;
    border: none;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    font-weight: 700;
    transition: 0.5s;
    cursor: pointer;
}
.button-style:hover {
    background: #308bcf;
}
.button-disabled {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    background: grey;
    border-radius: 12px;
    border: none;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    font-weight: 700;
    transition: 0.5s;
    cursor: not-allowed;
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-rows: 100px 1fr;
    height: fit-content;
    justify-content: center !important;
}
.contact-grid-header {
    grid-column-start: 1;
    grid-column-end: 9;
    margin: auto;
    text-align: center !important;
}
.contact-grid-form {
    grid-column-start: 3;
    grid-column-end: 7;
    text-align: center !important;
}

.form-message {
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .contact-grid {
        min-height: 200px;
    }
    .contact-grid-form {
        grid-column-start: 1;
        grid-column-end: 9;
        margin: 0 10px 0 10px;
        text-align: center !important;
    }
}