.about-wrapper {
    display: grid;
    padding: 0 50px 0 50px;
    grid-template-areas:
            "header header"
            "image content"
            "image content";
    grid-template-rows: 100px auto auto;
    min-height: 100vh;
    justify-content: center !important;
}
.header-content {
    grid-area: header;
    text-align: center !important;
}
.image-content {
    grid-area: image;
    text-align: center !important;
}
.about-content {
    grid-area: content;
    text-align: center !important;
    padding: 0 50px 0 50px;
    font-size: 18px;
}

a {
    cursor: pointer;
}
.text-green {
    color: #32a852;
}
.image {
    height: auto;
    max-width: 85%;
    border-radius: 360px;
}

.float-left {
    float: left;
}

.marginb-0 {
    margin-bottom: 0;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(8, minmax(auto, 1fr));
    grid-template-rows: 100px 1fr;
    min-height: 100vh;
    justify-content: center !important;
}
.about-grid-header {
    grid-column-start: 1;
    grid-column-end: 9;
    margin: auto;
    text-align: center !important;
}
.about-grid-image {
    grid-column-start: 2;
    grid-column-end: 4;
}
.about-grid-content {
    grid-column-start: 4;
    grid-column-end: 8;
    font-size: 24px;
}

@media only screen and (max-width: 600px) {
    .about-grid-image {
        grid-column-start: 2;
        grid-column-end: 8;
        text-align: center!important;
    }
    .about-grid-content {
        grid-column-start: 2;
        grid-column-end: 8;
        font-size: 20px;
    }
}