.navbar {
    overflow: hidden;
    height: 75px;
    background-color: #fff;
    border-bottom: rgba(0, 0, 0, 0.2) 2px solid;
    position: fixed;
    display: flex;
    z-index: 1;
}

.sticky-nav {
    position: sticky;
    top: 0;
    overflow-x: hidden;
}

nav ul {
    width: 75vw;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: auto;
    font-size: 24px;
}
@media only screen and (max-width: 600px) {
    nav ul {
        width: 100vw;
    }
    nav ul p {
        font-size: 16px;
    }
}

ul p {
    text-decoration: none;
    color: #32a852;
    font-weight: 400;
    transition: 0.3s;
    cursor: pointer;
}

ul p:hover {
    color: #308bcf !important;
}